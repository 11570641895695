import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loader = new BehaviorSubject<string>('default');
  castLoader = this.loader.asObservable();

  constructor() {}

  /**
   * showLoader
   */
  public showLoader() {
    this.loader.next('showloader');
  }

  /**
   * hideLoader
   */
  public hideLoader() {
    this.loader.next('hideloader');
  }

  /**
   * loggedIn
   */
  public loggedIn() {
    this.loader.next('loggedIn');
  }

  /**
   * loggedOut
   */
  public loggedOut() {
    this.loader.next('loggedOut');
  }
}
